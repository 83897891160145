//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import name from "../components/name.vue";
import { adoption } from "../common/api";
import gold from "./gold.vue";
export default {
  components: {
    name,
    gold,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    total: {
      type: String,
      default: "0",
    },
    money: {
      type: String,
      default: "0",
    },
  },
  data() {
    return {
      isshow: false,
      goldShow: false,
      erroeShow: false,
      buySucess: false, //购买成功
      message: "",
    };
  },
  computed: {},
  watch: {},
  created() {},
  methods: {
    guanbi(val) {
      this.isshow = val;
    },
    close() {
      this.$emit("update", !this.show);
    },
    goldUpdate: (function () {
      let timer = null;
      return function () {
        clearTimeout(timer);
        timer = setTimeout(() => {
          this.goldShow = !this.goldShow;
        }, 300);
      };
    })(),
    async sure() {
      const data = {
        access_id: this.$store.state.access_id,
        p_id: this.$store.state.p_id,
      };
      let res = await adoption(data);
      if (res.data.code == "80001") {
        //金币不足
        this.close();
        this.goldShow = true;
        return;
      }
      if (res.data.code == "200") {
        this.buySucess = !this.buySucess;
        setTimeout(() => {
          this.buySucess = !this.buySucess;
          this.isshow = !this.isshow;
        }, 2000);
      } else {
        this.message = res.data.message;
        this.erroeShow = !this.erroeShow;
        setTimeout(() => {
          this.erroeShow = !this.erroeShow;
        }, 2000);
      }
    },
  },
};
