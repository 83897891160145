//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getXieyi } from "../../common/api";
import upPop from "../../components/confirmPopUps.vue";
export default {
  components: {
    upPop,
  },
  data() {
    return {
      show: false, // 确认购买
      price: "", // 价格
      total: "", // 总价
      money: "", // 保证金
      type: "",
      xieyi: "",
    };
  },
  created() {
    this.price = this.$route.query.price;
    this.total = this.$route.query.total;
    this.money = this.$route.query.money;
    this.type = this.$route.query.type;
    this.Xieyi();
  },
  methods: {
    async Xieyi() {
      let res = await getXieyi({ type: 7 });
      console.log(res, "resresres");
      if (res.data.code != 200) return;
      this.xieyi = res.data.data.content;
    },
    disagree() {
      this.$router.go(-1);
    },
    close: (function (val) {
      let timer = null;
      return function () {
        clearTimeout(timer);
        timer = setTimeout(() => {
          this.show = val;
        }, 300);
      };
    })(),
    sure(val) {
      this.show = val;
    },
    pageClose() {
      this.$router.go(-1);
    },
  },
};
