//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { named } from "../common/api";
export default {
  props: {
    isshow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      name: "",
      erroeShow: false,
      message: "",
    };
  },
  methods: {
    async adopt() {
      if (this.name == "") {
        this.message = "请填写名字！";
        this.erroeShow = !this.erroeShow;
        setTimeout(() => {
          this.erroeShow = !this.erroeShow;
        }, 2000);
      } else {
        const data = {
          access_id: this.$store.state.access_id,
          p_id: this.$store.state.p_id,
          nickname: this.name,
        };
        let res = await named(data);
        if (res.data.code != 200) {
          this.message = res.data.message;
          this.erroeShow = !this.erroeShow;
          setTimeout(() => {
            this.erroeShow = !this.erroeShow;
          }, 2000);
          return;
        }
        if (res.data.code == "200") {
          this.closePage();
        }
      }
    },
    close: (function () {
      let timer = null;
      return function () {
        clearTimeout(timer);
        timer = setTimeout(() => {
          this.$emit("update", !this.isshow);
        }, 300);
      };
    })(),
    closePage() {
      window.location.href = "uniwebview://nameClose?test=nameClose";
    },
  },
};
